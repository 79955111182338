import React from "react";
import {Router} from "@reach/router"
import GlosarioDetail from "../../../components/glosario-financiero/GlosarioDetail";

const GlossariesIndex = () => {

    return (
        <Router basepath="/balanz-university/glosario-financiero">
            <GlosarioDetail path="/:slug"/>
        </Router>
    );
};

export default GlossariesIndex;